<template>
  <div id="headPage">
    <div class="headCenter">
      <div class="headLeft">
        <img
          src="../../assets/loginIndexNew/LogoNew2x.png"
          alt=""
          @click="goLogin"
          style="width: 42px;height: 21px;"
        />
        <el-divider direction="vertical"></el-divider>
        <span>安知职业培训平台</span>
      </div>
      <div :class="activeName != 6?'headRight':'headRight hideActiveBar'">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="首页" name="1"></el-tab-pane>
          <el-tab-pane label="课程资源" name="2"></el-tab-pane>
          <el-tab-pane label="工伤预防" name="7"></el-tab-pane>
          <el-tab-pane label="数字服务" name="3"></el-tab-pane>
          <el-tab-pane label="商务合作" name="4"></el-tab-pane>
          <el-tab-pane label="关于晟融" name="5"></el-tab-pane>
          <el-tab-pane label="登录" name="6"></el-tab-pane>
        </el-tabs>
      </div>

    </div>

    <el-dialog
        title=""
        :visible.sync="dialogVisible"
        width="348px"
        top="15%"
        :before-close="loginTypeHandleClose"
        :modal-append-to-body="false"
        :close-on-click-modal="false">
      <div class="loginBox">
        <el-tabs v-model="loginType" @tab-click="loginTypeHandleClick">
          <el-tab-pane label="管理登录" name="1"></el-tab-pane>
          <el-tab-pane label="学员登录" name="2"></el-tab-pane>
        </el-tabs>
        <template v-if="loginType == '1'">
          <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              class="login_input"
          >
            <el-form-item prop="username">
              <el-input
                  v-model="ruleForm.username"
                  class="loginput"
                  clearable
                  @keyup.enter.native="submit()"
                  placeholder="请输入您的账号"
                  @focus="
                        (e) => {
                          clear('username');
                        }
                      "
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                  v-model.trim="ruleForm.password"
                  type="password"
                  class="loginput"
                  clearable
                  @keyup.enter.native="submit()"
                  placeholder="请输入密码"
                  @focus="
                        (e) => {
                          clear('password');
                        }
                      "
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="code">
              <div class="flexcb code" style="width: 100%">
                <el-input
                    style="width: 181px;margin-right: 10px;"
                    class="flex1"
                    clearable
                    v-model="ruleForm.code"
                    placeholder="验证码"
                    :maxlength="50"
                    @keyup.enter.native="submit()"
                    @focus="
                          (e) => {
                            clear('code');
                          }
                        "
                ></el-input>
                <img
                    @click="getYzm"
                    :src="yzmInfo.imageBase64"
                    alt=""
                    class="codeImg"
                />
              </div>
            </el-form-item>
          </el-form>
          <div class="loginTextButton">
            <el-button
                type="text"
                @click="ForgetPassword"
            >忘记密码?</el-button
            >
            <el-button
                type="text"
                @click="toRegisteredAccount"
            >用户注册</el-button
            >
          </div>
          <div class="loginBottomButton">
            <el-button @click="submit()">立即登录</el-button>
          </div>
        </template>
        <template v-if="loginType == '2'">
          <div class="loginControl">
            <div class="login_box">
              <img class="switch" @click="switchLoginMethodXY(2)" v-if="loginMethodXY === 1" src="@/assets/loginIndexNew/switch-pc.png" alt="" />
              <img class="switch" @click="switchLoginMethodXY(1)" v-else src="@/assets/loginIndexNew/switch-phone.png" alt=""/>

              <!-- 验证码登录 -->

              <el-form v-if="loginMethodXY == 1" :model="ruleFormXY" :rules="rulesXY" ref="ruleFormXY" class="login_input">
                <el-form-item prop="mobilePhone">
                  <!--                  <img src="@/assets/login/login_phone.png" alt style="width: 21px; height: 24px"/>-->
                  <el-input
                      v-model="ruleFormXY.mobilePhone"
                      placeholder="请输入您的手机号码"
                      class="loginput" clearable maxlength="11"
                      @input="filterXY($event, 'mobilePhone')"
                      @focus="(e) => {clearXY('mobilePhone');}">
                  </el-input>
                </el-form-item>
                <el-form-item prop="msgCode" key="adfsd" class="loginputHasbtnBox">
                  <!--                  <img src="@/assets/login/login_code.png" alt style="width: 22px; height: 24px"/>-->
                  <el-input
                      v-model="ruleFormXY.msgCode"
                      placeholder="请输入验证码" type="text"
                      class="loginput loginputHasbtn" clearable
                      @input="filterXY($event, 'msgCode')"
                      @focus="(e) => {clearXY('msgCode');}"
                      @keyup.enter.native="submitXY()">
                  </el-input>
                  <el-button v-if="sendShowXY" @click="getCheckCodeXY()" type="text">获取验证码</el-button>
                  <span class="yzmNum" v-else style="color: #66b1ff">{{ totalTimeXY }}</span>
                </el-form-item>
                <!--                <el-form-item class="autoLogin">-->
                <!--                  <el-checkbox v-model="ruleFormXY.autoLogin">30天自动登录</el-checkbox>-->
                <!--                </el-form-item>-->
              </el-form>

              <!-- 账号密码登录 -->
              <el-form v-else :model="passwordForm" :rules="rulesXY" ref="passwordForm" class="login_input">
                <el-form-item prop="userName">
                  <!--                  <img src="@/assets/login/login_user.png" alt style="width: 21px; height: 24px"/>-->
                  <el-input
                      v-model="passwordForm.userName"
                      placeholder="请输入您的账号"
                      class="loginput" clearable maxlength="11"
                      @focus="(e) => {clearPasswordFormXY('userName');}">
                  </el-input>
                </el-form-item>
                <el-form-item prop="password">
                  <!--                  <img src="@/assets/login/login_password.png" alt style="width: 22px; height: 24px"/>-->
                  <el-input
                      v-model="passwordForm.password"
                      placeholder="请输入密码" type="password"
                      autocomplete="new-password"
                      class="loginput" clearable
                      @focus="(e) => {clearPasswordFormXY('password');}"
                      @keyup.enter.native="submitXY()">
                  </el-input>
                </el-form-item>
                <el-form-item class="autoLogin">
                  <!--                  <el-checkbox v-model="ruleFormXY.autoLogin">30天自动登录</el-checkbox>-->
                  <span class="forget" @click="forgetPasswordXY">忘记密码？</span>
                </el-form-item>
              </el-form>

              <div class="loginButton">
                <el-button type="primary" @click="submitXY()">立即登录</el-button>
              </div>
              <!--              <div class="divider">-->
              <!--                <el-divider><span>温馨提示</span></el-divider>-->
              <!--              </div>-->
              <p class="loginTips">未注册本平台账户的手机号，登录时将自动注册且代表您已同意
                <el-link type="primary" :underline="false" @click="jumpXY(1)">《用户服务协议》</el-link>
                <el-link type="primary" :underline="false" @click="jumpXY(2)">《隐私协议》</el-link>
              </p>
            </div>
          </div>
        </template>
      </div>
    </el-dialog>
    <!--协议弹层-->
    <el-dialog :title="titleXY" :visible.sync="dialogVisibleXY" width="600px" @close="handleCloseXY" :modal-append-to-body="false">
      <div>
        <iframe :src="srcXY" frameborder="0" scrolling="auto" width="100%" height="400px"></iframe>
      </div>
    </el-dialog>

    <el-dialog
        title="获取验证码"
        :visible.sync="dialogVisiblemobile"
        width="30%"
        top="15%"
        :center="true"
        :before-close="doCloseMobile"
        :modal-append-to-body="false"
        :close-on-click-modal="false"
    >
      <el-form
          label-width="120px"
          ref="editForm"
          :model="editForm"
          :rules="editrules"
      >
        <el-form-item label="验证码" prop="code">
          <el-input
              v-model="editForm.code"
              type="text"
              size="small"
              placeholder="请输入验证码"
          >
            <el-button
                slot="append"
                @click="getCode"
                :disabled="codeDisabled"
                size="small"
            >{{ codemsg }}</el-button
            >
          </el-input>
        </el-form-item>
        <!-- <el-form-item label="验证码" prop="code" class="Classitem">
          <el-input
            v-model="editForm.code"
            type="text"
            size="small"
            placeholder="请输入验证码"
          />
        </el-form-item> -->
      </el-form>
      <span
          slot="footer"
          class="dialog-footer"
          style="display: flex; justify-content: center"
      >
        <el-button @click="doCloseMobile()" class="bgc-bv">取消</el-button>
        <el-button class="bgc-bv" @click="editmobileSure('editForm')"
        >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import AES from "@/utils/aes";

export default {
  name: "wrongAuthenticatePage_headPage",
  props: {
    activeName: {
      type: String,
      default: ""
    }
  },
  data() {
    //验证码校验
    let validateCode = (rule, value, callback) => {
      if (value.trim() != "") {
        if (value != this.verificationCode) {
          callback(new Error("请输入正确的验证码"));
        } else {
          callback();
        }
      } else {
        callback(new Error("请输入验证码"));
      }
    };

    //验证码校验
    const validatePhoneXY = (rule, value, callback) => {
      // console.log('123',value)
      if (value.trim() === "") {
        callback(new Error("请输入手机号码"));
      } else if (value && !this.checkReg(value, "mobile")) {
        callback(new Error("请输入正确的手机号码"));
      } else {
        callback();
      }
    };

    // 验证 验证码
    const validateMsgCodeXY = (rule, value, callback) => {
      if (value.trim() === "") {
        callback(new Error("请输入验证码"));
      } else if (value && !this.checkReg(value, "msgCode")) {
        callback(new Error("验证码错误"));
      } else {
        callback();
      }
    };

    // 验证用户名
    const validateUserNameXY = (rule, value, callback) => {
      if (value.trim() === "") {
        callback(new Error("请输入账号"));
      } else if (value && !this.checkReg(value, "userName")) {
        callback(new Error("请输入正确的账号"));
      } else {
        callback();
      }
    };

    // 验证密码
    const validatePasswordXY = (rule, value, callback) => {
      if (value.trim() === "") {
        callback(new Error("请输入密码"));
      } else if (value && !this.checkReg(value, "passwordComplex")) {
        callback(new Error("请输入正确的密码"));
      } else {
        callback();
      }
    };
    return{
// 登录数据
      ruleForm: {
        username: "",
        password: "",
        code: "",
      },
      rules: {
        username: [
          {
            required: true,
            message: "请输入您的账号",
            trigger: ["blur", "change"],
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: ["blur", "change"],
          },
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: ["blur", "change"],
          },
        ],
      },
      //验证码
      verificationCode: this.createCode(),
      dialogVisiblemobile: false,
      editForm: {},
      codemsg: "获取验证码",
      auth_time: 180,
      codeDisabled: false,
      editrules: {
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: ["blur"],
          },
        ],
      },
      // 获取服务器的验证码
      yzmInfo: {
        imageBase64: "",
        key: "",
      },
      homeActiveName: "",

      loginType:'1',
      dialogVisible: false,



      // 学员登录
      loginMethodXY: 1, // 登录的方法  1：验证码登录 2：密码登录
      dialogVisibleXY: false, //协议弹层
      srcXY: "", //协议路径
      titleXY: "", //协议表头
      // 账号登录
      ruleFormXY: {
        mobilePhone: "", // 手机号
        msgCode: "", // 验证码
        // autoLogin: false, // 是否存储30天
      },
      // 账号密码登录
      passwordForm: {
        userName: "",
        password: "",
      },
      // 账号登录、密码登录 字段验证
      rulesXY: {
        mobilePhone: [
          {
            required: true,
            validator: validatePhoneXY,
            trigger: ["blur"],
          },
        ],
        msgCode: [
          {
            required: true,
            validator: validateMsgCodeXY,
            trigger: ["blur"],
          },
        ],
        userName: [
          {
            required: true,
            validator: validateUserNameXY,
            trigger: ["blur"],
          },
        ],
        password: [
          {
            required: true,
            validator: validatePasswordXY,
            trigger: ["blur"],
          },
        ],
      },
      totalTimeXY: 180,
      countIntervalXY: null,
      sendShowXY: true,
    }

  },
  created() {
    this.$delAllStorage2()
    this.getYzm();
    // 记录上次登录方式-防止从上一个页面返回来自动回到上一次登录方式
    this.loginMethodXY = this.$route.params.loginMethodXY || 1;
  },
  methods: {
    handleClick(e){
      let gatherInfoParams = {
        contentId: '',// 内容的系统id
        contentName: '',// 内容名称
        moduleCode: 'HOME',
        /* 模块标识
          HOME - 首页,
          COURSE - 课程推广页,
          DIGITAL_SERVICE - 数字服务,
          BUSINESS_COOPERATION - 商务合作
        */
        positionCode: 'NAVIGATION_BAR',
        /* 位置标识
          【PC_HR - HOME】
          NAVIGATION_BAR - 导航栏 （首页/课程资源/数字服务/商务合作/关于晟融/登录）
          INTELLIGENT_TOOLS - 智能化工具
          HR_PATTERN  - 人力资源新模式]
          【PC_HR - COURSE】
          CATEGORY - 分类
          COURSE - 课程
        */
        topNum: ''// 顺序编号
      }
      if(e._props.name == '1'){
        this.onHome(e._props.name)
        gatherInfoParams.contentName = '首页'
        gatherInfoParams.topNum = 1
      }
      if(e._props.name == '2'){
        this.onCourseCenter()
        gatherInfoParams.contentName = '课程资源'
        gatherInfoParams.topNum = 2
      }
      if(e._props.name == '3'){
        this.onDigitalServices()
        gatherInfoParams.contentName = '数字服务'
        gatherInfoParams.topNum = 3
      }
      if(e._props.name == '4'){
        this.onBusinessCooperation()
        gatherInfoParams.contentName = '商务合作'
        gatherInfoParams.topNum = 4
      }
      if(e._props.name == '5'){
        this.onAboutUsNew()
        gatherInfoParams.contentName = '关于晟融'
        gatherInfoParams.topNum = 5
      }
      if(e._props.name == '6'){
        this.loginShow()
        gatherInfoParams.contentName = '登录'
        gatherInfoParams.topNum = 6
      }
      if(e._props.name == '7'){
        this.inductrialInjuryPrevention()
        gatherInfoParams.contentName = '工伤预防'
        gatherInfoParams.topNum = 7
      }
      this.$gatherInfo(gatherInfoParams)
    },
    // 首页
    onHome(name,popShow){
      this.$router.push({
        path: "/web/login",
        query:{
          name
        }
      });
    },
    // 课程资源
    onCourseCenter() {
      this.$router.push({
        path: "/wrongAuthenticatePage/courseCenter",
      });

      // if (
      //   process.env.NODE_ENV == "development" ||
      //   (process.env.NODE_ENV == "production" &&
      //     process.env.VUE_APP_URL == "development")
      // ) {
      //   window.open("http://192.168.3.112:9000/anzhi-personal-courses/#/");
      // } else {
      //   window.open("https://anzhi.bjsrxx.com/anzhi-personal-courses");
      // }
    },
    // 数字服务
    onDigitalServices() {
      this.$router.push({
        path: "/wrongAuthenticatePage/digitalServices",
      });
    },
    // 商务合作
    onBusinessCooperation() {
      this.$router.push({
        path: "/wrongAuthenticatePage/businessCooperation",
      });
    },
    // 关于晟融
    onAboutUsNew() {
      this.$router.push({
        path: "/wrongAuthenticatePage/aboutUsNew",
      });
    },
    // 个人学习
    // onPersonalLearning() {
    //   if (
    //     process.env.NODE_ENV == "development" ||
    //     (process.env.NODE_ENV == "production" &&
    //       process.env.VUE_APP_URL == "development")
    //   ) {
    //     window.open(
    //       "http://192.168.3.112:9000/anzhi-web-app-new/#/web/login"
    //     );
    //   } else {
    //     window.open(
    //       "https://anzhi.bjsrxx.com/web-app-new"
    //     );
    //   }
    //   // this.$router.push({
    //   //   path: "/wrongAuthenticatePage/courseCenter",
    //   //   query: {},
    //   // });
    // },
    // 跳转关于
    // onAboutUs() {
    //   this.$router.push({
    //     path: "/wrongAuthenticatePage/aboutUs",
    //     query: {},
    //   });
    // },
    // 商务合作
    // onJoinUs() {
    //   this.$router.push({
    //     path: "/wrongAuthenticatePage/joinUs",
    //     query: {},
    //   });
    // },
    // 点击logo去首页登录
    goLogin() {
      this.$router.push({
        path: "/",
      });
    },
    // 工伤预防
    inductrialInjuryPrevention(){
      this.$router.push({
        path: "/wrongAuthenticatePage/inductrialInjuryPrevention",
      });
    },
    // 正则合集
    checkReg(val, regName) {
      let reg = {
        userName: /^[a-zA-Z0-9_]{2,16}$/,
        password: /^[A-Za-z0-9]+$/,
        passwordComplex:/^(?!\d+$)(?![\/:@[`{~]+$)(?![a-zA-Z]+$)[0-9A-Za-z\/:@[`{~]{8,20}$/,
        msgCode:/^\d{6}$/,
        mobile: /^1\d{10}$/,
      };
      return reg[regName].test(val);
    },
    // 登录类型切换
    loginTypeHandleClick(){
      this.clearLoginInfo()
    },
    // 登录弹层显示
    loginShow(xy,stu){
      // 是否优先显示学员登录
      this.dialogVisible = true
      this.loginType = '1'
      if(xy){
        this.loginType = '2'
      }
      this.loginMethodXY = 1
      if(stu){
        this.loginMethodXY = 2
      }
      this.clearLoginInfo()

    },
    clearLoginInfo(){
      if(this.$refs.ruleForm){
        this.$refs.ruleForm.resetFields();
      }
      if(this.$refs.ruleFormXY){
        this.$refs.ruleFormXY.resetFields();
      }
      if(this.$refs.passwordForm){
        this.$refs.passwordForm.resetFields();
      }
    },
    // 关闭登录弹层
    loginTypeHandleClose(){
      this.dialogVisible = false
    },
    clear(val) {
      const el = this.$refs["ruleForm"];
      el.clearValidate(val);
    },
    createCode() {
      //通过随机数生成验证码
      let code = "";
      let codeLength = 4; //验证码长度
      let random = new Array(
          0,
          1,
          2,
          3,
          4,
          5,
          6,
          7,
          8,
          9,
          "a",
          "b",
          "c",
          "d",
          "e",
          "f",
          "g",
          "h",
          "i",
          "j",
          "k",
          "l",
          "m",
          "n",
          "o",
          "p",
          "q",
          "r",
          "s",
          "t",
          "u",
          "v",
          "w",
          "x",
          "y",
          "z"
      );
      for (let i = 0; i < codeLength; i++) {
        let index = Math.floor(Math.random() * 36);
        code += random[index];
      }
      // return code;
      return code;
    },
    refreshCode() {
      /**
       * 生成验证码
       * @param
       */
      this.verificationCode = this.createCode();
    },
    // 获取验证码
    getYzm() {
      this.$post("/sys/captcha/get").then((res) => {
        this.yzmInfo.imageBase64 = res.data.imageBase64;
        this.yzmInfo.key = res.data.key;
        this.ruleForm.code = "";
      });
    },
    // 登录
    submit(formName = "ruleForm") {
      const el = this.$refs[formName];
      el.validate((valid) => {
        if (valid) {
          this.ruleForm.username = this.ruleForm.username.replace(
              /^\s+|\s+$/g,
              ""
          );
          this.$post(
              "/sys/admin/validation",
              {
                password: this.ruleForm.password.trim(),
                username: this.ruleForm.username,
              },
              3000,
              false
          ).then((ret) => {
            if (ret.data.enable) {
              this.dialogVisiblemobile = true;
              this.editForm.editmobile = AES.decrypt(ret.data.mobile);
            } else {
              this.$post(
                  "/login",
                  {
                    password: this.ruleForm.password,
                    username: this.ruleForm.username,
                    captchaKey: this.yzmInfo.key,
                    captchaValue: this.ruleForm.code,
                  },
                  3000,
                  false
              )
                  .then((ret1) => {
                    this.cacheUserData(ret1);
                  })
                  .catch(() => {
                    this.$store.dispatch("handleDelAll");
                    this.getYzm();
                  });
            }
          });
        } else {
          // this.ruleForm = {
          //   username: "",
          //   password: "",
          //   code: ""
          // };
          this.refreshCode();
        }
      });
    },
    //验证码倒计时
    getAuthCode() {
      if (this.authTimeTimer) {
        clearTimeout(this.authTimeTimer);
      }
      this.authTimeTimer = setTimeout(() => {
        this.auth_time -= 1;
        this.codemsg = this.auth_time + "s";
        this.codeDisabled = true;
        if (this.auth_time < 0) {
          this.codeDisabled = false;
          this.codemsg = "获取验证码";
          clearTimeout(this.authTimeTimer);
        } else {
          this.getAuthCode();
        }
      }, 1000);
    },
    // 发送手机验证码
    getCode() {
      this.auth_time = 180;
      this.codeDisabled = false;
      this.$post("/sys/sms/login/captcha", { mobile: this.editForm.editmobile })
          .then((ret) => {
            this.getAuthCode();
            this.$confirm("验证码已发送至手机号上，请查收。", {
              confirmButonText: "确定",
              center: true,
              confirmButtonClass: "bgc-bv",
              modal: false,
              beforeClose: (action, instance, done) => {
                instance.modal = true;
                done();
              },
            });
          })
          .catch((err) => {
            return;
          });
    },
    // 确定
    editmobileSure(editForm) {
      this.$refs[editForm].validate((valid) => {
        if (valid) {
          this.$post("/login", {
            password: this.ruleForm.password,
            username: this.ruleForm.username,
            captchaKey: this.yzmInfo.key,
            captchaValue: this.ruleForm.code,
            smsCode: this.editForm.code,
          })
              .then((ret) => {
                this.dialogVisiblemobile = false;
                this.codemsg = "获取验证码";
                this.codeDisabled = false;
                this.editForm = {};
                this.cacheUserData(ret);
              })
              .catch(() => {
                this.$store.dispatch("handleDelAll");
              });
        } else {
          return false;
        }
      });
    },
    // 获取验证码
    doCloseMobile() {
      // this.dialogVisible = false;
      this.dialogVisiblemobile = false;
      this.$refs["editForm"].resetFields();
      this.editForm = {
        editmobile: "",
        code: "",
      };
      clearTimeout(this.authTimeTimer);
      this.codemsg = "获取验证码";
      this.codeDisabled = false;
    },
    // 缓存登录数据
    cacheUserData(ret) {
      const datas = ret.data || {};
      this.roleId = datas.principal.roleId;
      // 缓存参数
      this.$store.dispatch("handleToken", datas.token || "");
      this.$store.dispatch("handleUser", {
        compId: datas.principal.compId || "",
        userId: datas.userId || "",
        fullname: datas.principal.fullname || "",
        roleId: datas.principal.roleId || "",
        passwordExpire: datas.principal.passwordExpire,
        trainTypeIdRange: datas.principal.trainTypeIdRange,
        createTime: datas.principal.createTime,
        tempAccount: datas.principal.tempAccount,
        videoIsMark: datas.principal.videoIsMark,
        areaId: datas.principal.areaId || "",
      });
      if (JSON.parse(sessionStorage.getItem("userJson")).passwordExpire) {
        this.$router.push("/web/operate/change");
      } else {
        this.getMenu();
      }
    },
    // 获取左侧路由
    getMenu() {
      this.$post("/sys/per/menu/userId", {}, 3000, false)
          .then((res) => {
            const data = res.data || [];
            this.setDictionary().then(() => {
              let url = (data.find((i) => i.name == "首页") || {}).url || null;
              if (this.roleId != "2" && url) {
                this.$router.push(url);
              } else {
                this.$router.push({
                  path: data[0].url ? data[0].url : data[1].url,
                });
              }
            });
            this.$store.dispatch("handleMenu", data || []);
          })
          .catch(() => {
            this.$store.dispatch("handleDelAll");
          });
    },
    // 数据字典
    setDictionary() {
      return new Promise((resolve, reject) => {
        this.$post("/sys/dictionary/all", {}, 3000, false).then((ret) => {
          const retData = ret.data || [];
          let dictionaryArr = [];
          let dictionaryJson = {};
          retData.forEach((item) => {
            if (!dictionaryArr.includes(item.dictKey)) {
              dictionaryArr.push(item.dictKey);
              dictionaryJson[item.dictKey] = {};
            }
            dictionaryJson[item.dictKey][item.dictCode] = item.dictValue;
          });
          this.$store.dispatch("handleDictionary", dictionaryJson);
          resolve();
        });
      });
    },
    // 判断浏览器
    checkbrowser() {
      if (navigator.userAgent.indexOf("Edge") >= 0) {
        //微软edge

        alert(
            "抱歉！暂时还无法兼容您当前的浏览器！为了能正常使用网址功能，推荐使用谷歌浏览器，QQ浏览器（极速模式），360浏览器（极速模式）"
        );
      } else if (
          navigator.userAgent.indexOf("Chrome") >= 0 &&
          navigator.userAgent.indexOf("WebKit") >= 0
      ) {
        //谷歌内核
      } else if (navigator.userAgent.indexOf("AppleWebKit") >= 0) {
        //mac谷歌内核
      } else {
        alert(
            "抱歉！暂时还无法兼容您当前的浏览器！为了您能正常使用网址功能，推荐使用谷歌浏览器，QQ浏览器（极速模式），360浏览器（极速模式）"
        );
      }
    },
    //忘记密码
    ForgetPassword() {
      this.$router.push("/web/ForgetPassword");
    },
    //用户注册
    toRegisteredAccount() {
      this.$router.push('/web/login/registeredAccount')
    },
    /* 学员登录 */
    // 获取验证码
    getCheckCodeXY() {
      this.$refs.ruleFormXY.validateField("mobilePhone", (valid) => {
        if (!valid) {
          this.sendShowXY = false;

          this.$post("/sys/sms/captcha", {
            mobile: this.ruleFormXY.mobilePhone,
          }, 3000, true,5).then(() => {
            this.$message.success("验证码已发送到您的手机上!");
            this.countDownXY();
          });
        }
      });
    },
    // 验证码倒计时
    countDownXY() {
      clearInterval(this.countIntervalXY);
      this.countIntervalXY = setInterval(() => {
        this.totalTimeXY--;
        if (this.totalTimeXY < 0) {
          clearInterval(this.countIntervalXY);
          this.totalTimeXY = 180;
          this.sendShowXY = true;
        }
      }, 1000);
    },
    // 清除：账号，密码，验证码等信息
    clearXY(val) {
      const el = this.$refs["ruleFormXY"];
      el.clearValidate(val);
    },
    // 学员登录
    submitXY() {
      let _this = this;
      // 如果是验证码登录走这
      if (_this.loginMethodXY === 1) {
        _this.$refs["ruleFormXY"].validate((valid) => {
          if (valid) {
            _this.$post("/auth/sms", {
              mobile: _this.ruleFormXY.mobilePhone,
              captcha: _this.ruleFormXY.msgCode,
              force: true,
              macAdd: localStorage.getItem("macAdd") || ""
            }, 3000, true,5)
                .then((res) => {
                  let data = AES.decrypt(res.data)
                  localStorage.setItem('token',data.token)
                  localStorage.setItem('userJson', JSON.stringify(data.principal))
                  // _this.$setCookie("testCookie", data.token, 30);
                  // _this.$setCookie(
                  //     "userJson",
                  //     JSON.stringify(data.principal),
                  //     1
                  // );
                  _this.onPersonalLearning()
                })
                .catch(() => {
                  _this.ruleFormXY.msgCode = "";
                });
          }
        });
        // 如果是密码登录走这
      } else {
        _this.$refs["passwordForm"].validate((valid) => {
          if (valid) {

            _this.$post("/auth/password", {
              mobile: _this.passwordForm.userName,
              password: _this.passwordForm.password,
              force: true,
              macAdd: localStorage.getItem("macAdd") || ""
            }, 3000, true,5)
                .then((res) => {
                  let data = AES.decrypt(res.data)
                  localStorage.setItem('token',data.token)
                  localStorage.setItem('userJson', JSON.stringify(data.principal))
                  _this.onPersonalLearning()
                });
          }
        });
      }
    },
    onPersonalLearning() {

      if(process.env.NODE_ENV == "development"){
        // 如果是本地环境-请换成启动的本地web-app-new的地址,如果域名不一致会拿不到cookie，导致web-app-new没办法自动登录
        window.open(
            "http://localhost:8090/#/web/login"
        );
        // window.open(
        //     "http://192.168.3.112:9000/anzhi-web-app-new/#/web/login"
        // );
      } else {
        // 如果是测试环境或者线上环境
        if( document.domain == 'zyjk.sunreal.cn'){
          // 测试环境-外网
          window.open(
              "https://zyjk.sunreal.cn/web-app-new/#/web/login"
          );
        }else if(document.domain == '192.168.3.112'){
          // 测试环境-内网
          window.open(
              "http://192.168.3.112:9000/web-app-new/#/web/login"
          );
        }else{
          // 线上环境
          window.open(
              "https://anzhi.bjsrxx.com/web-app-new"
          );
        }
      }

    },
    // 输入框自动去除非数字
    filterXY(value, inputName) {
      value = value.replace(/\D/g, "");
      const { ruleFormXY, passwordForm } = this;
      if (ruleFormXY[inputName]) return (ruleFormXY[inputName] = value);
      passwordForm[inputName] = value;
    },
    // 协议
    jumpXY(stu) {
      this.dialogVisibleXY = true;
      if (stu == 1) {
        this.srcXY = "https://anzhi.bjsrxx.com/banner/servicePc.html";
        this.titleXY = "用户服务协议";
      } else {
        this.srcXY = "https://anzhi.bjsrxx.com/banner/privacyPc.html";
        this.titleXY = "隐私协议";
      }
    },
    handleCloseXY() {
      this.dialogVisibleXY = false;
      this.srcXY = "";
      this.titleXY = "";
    },
    // 切换登录的方法
    switchLoginMethodXY(type) {
      const {
        $refs: { ruleFormXY, passwordForm },
      } = this;
      this.clearLoginInfo()
      this.loginMethodXY = type;
    },
    // 清空账号密码登录表单
    clearPasswordFormXY(val) {
      const el = this.$refs["passwordForm"];
      el.clearValidate(val);
    },
    // 忘记密码
    forgetPasswordXY() {
      sessionStorage.setItem("loginMethod",this.loginMethodXY);
      this.$router.push({
        path: "/wrongAuthenticatePage/forgetPassChange",
        query: {
          forgetPasswordType: 1,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>

#headPage {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: 0px 3px 30px 1px rgba(0,0,0,0.08);
  .headCenter {
    width: 1440px;

    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /deep/.el-tabs__nav-scroll,/deep/.el-tabs__nav-wrap{
      overflow: unset;
    }
    /deep/.el-tabs--top .el-tabs__item.is-top:last-child{
      transition: 0.3s;
      position: relative;
      color: #2878ff;
      &:before{
        content: '';
        position: absolute;
        top: 59px;
        right: -13px;
        width: 60px;
        height: 3px;
        background: #fff;
        z-index: 1;
      }
      &:after{
        content: '';
        position: absolute;
        top: 13px;
        right: -16px;
        width: 65px;
        height: 34px;
        border-radius: 17px;
        border: 1px solid #2878ff;
        background: transparent;
        transition: 0.3s;
        z-index: -1;
      }
      &:hover{
        color: #fff;
        &:after{
          background: #2878ff;
        }
      }
    }
    .headLeft {
      display: flex;
      align-items: center;
      span {
        font-size: 16px;
        font-weight: 400;
        color: #333;
      }
    }
    .headRight{
      height: 100%;
      padding-right: 18px;
      /deep/.el-tabs{
        height: 100%;

      }
      /deep/.el-tabs__nav-wrap{
        margin: 0;
        height: 100%;
      }
      /deep/.el-tabs__nav-scroll{
        height: 100%;
      }
      /deep/.el-tabs__nav{
        height: 100%;
      }
    }
  }
  img {
    cursor: pointer;
  }

}
@media (max-width: 1500px) {
  #headPage .headCenter{
    width: 1200px;
  }

}
.clearfix:after{
  content:"";//设置内容为空
  height:0;//高度为0
  line-height:0;//行高为0
  display:block;//将文本转为块级元素
  visibility:hidden;//将元素隐藏
  clear:both;//清除浮动
}
.clearfix{
  zoom:1;//为了兼容IE
}

/deep/.el-tabs__header{
  height: 100%;
  margin-bottom: 0;
}
/deep/.el-tabs__item{
  padding: 0 0 0 40px;
  font-size: 16px;
  font-weight: 400;
  height: 100%;
  line-height: 62px;
}
/deep/.el-tabs__item:hover{
  color: #2878FF;
}
/deep/.el-tabs__nav-wrap::after{
  height: 0;
}
/deep/.el-tabs__item.is-active{
  color: #2878FF;
}

/deep/.el-tabs__active-bar{
  background-color: #2878FF;
  box-sizing: border-box;
  border-left: 6px solid #fff;
  border-right: 6px solid #fff;
  transition: unset;
}
.hideActiveBar{
  /deep/.el-tabs__active-bar{
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
  }
}






/deep/.el-dialog{
  border-radius: 8px;
}
/deep/.el-dialog__header{
  padding: 0 !important;
  height: 0;
}
/deep/.el-dialog__header .el-dialog__headerbtn .el-dialog__close{
  color: #666;
  transition: 0.3s;
  &:hover{
    color: #2878FF;
  }
}
/deep/.el-dialog__header .el-icon-close:before{
  border: 0!important;
}
/deep/.el-dialog__header .el-dialog__headerbtn{
  left: 14px;
  right: unset;
  top: 14px;
  bottom: unset;
  transform: translateY(0);
}
/deep/.el-dialog__body{
  padding: 44px 40px 40px;

}
.loginBox{
  /deep/.el-tabs__header{
    margin-bottom: 20px;
  }
  /deep/.el-tabs__nav-wrap::after{
    height: 0;
  }
  /deep/.el-tabs__active-bar{
    border-left: 16px solid #fff;
    border-right: 16px solid #fff;
    background-color: #2878FF;

  }

  /deep/.el-form-item{

    margin-bottom: 30px!important;
  }
  /deep/.el-tabs__item{
    font-weight: bold;
    font-size: 18px;
    color:#333;
  }
  /deep/.el-tabs__item.is-active{
    color: #2878FF;
  }
  .loginTextButton{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /deep/.el-button--text{
      padding: 0;
      color: #2878FF;
    }
  }
  .loginBottomButton{
    display: flex;
    margin-top: 15px;
    &>.el-button {
      width: 100%;
      height: 40px;
      background: #2878ff;
      border: #2878ff;
      color: #fff
    }
  }

}

//学员登录
//.login_input {
//  display: flex;
//  flex-direction: column;
//  flex: 1;
//  .el-form-item {
//    margin-top: 0.5rem;
//    border-bottom: 1px solid #ccc;
//    display: flex;
//    align-items: center;
//    .loginput input {
//      border: none;
//    }
//    .forget {
//      height: 0.6875rem;
//      font-size: 0.875rem;
//      font-family: PingFangSC-Regular, PingFang SC;
//      font-weight: 400;
//      color: #409eff;
//      line-height: 0.6875rem;
//      cursor: pointer;
//      display: block;
//      flex-grow: 1;
//      text-align: end;
//    }
//  }

//  .el-form-item__content {
//    display: flex;
//    align-items: center;
//    flex: 1;
//  }
//}
.loginControl {
  //padding: 0 2.625rem;
  /deep/.el-button--text{
    color: #2878FF;
  }
  .login_box {
    .autoLogin {
      border-bottom: none;
      margin-bottom: 15px!important;
      line-height: unset;
      /deep/.el-form-item__content{
        line-height: unset;
      }
      .forget {
        width: 70px;
        font-size: 14px;
        font-weight: 400;
        color: #2878FF;
        cursor: pointer;
        display: block;
        flex-grow: 1;
        text-align: start;
      }
    }
    .loginputHasbtnBox{
      position: relative;
      /deep/.el-button--text{
        position: absolute;
        top: 0;
        right: 14px;
      }
      .yzmNum{
        position: absolute;
        top: 0;
        right: 38px;
      }
    }
    .loginputHasbtn{
      /deep/.el-input__inner{
        padding-right: 100px;
      }
      /deep/.el-input__icon{
        position: relative;
        right: 98px;
      }
    }
    //padding: 3.75rem 3.05rem;
    //background-color: #fff;
    //border-radius: 0.625rem;
    .switch {
      right: 0;
      top: 0;
      position: absolute;
      width: 78px;
      height: 78px;
      cursor: pointer;
    }
    //.divider {
    //  padding: 0 0.5rem;
    //}
    .loginButton {
      margin-bottom: 20px;
      text-decoration: none;

      /deep/.el-button--primary{
        background-color: #2878FF;
        border-color: #2878FF;
      }
      > .el-button {
        width: 100%;
        height: 2.5rem;
        font-size: 0.8rem;
      }
    }
    .loginTips {
      font-size: 0.7rem;
      text-align: left;
      line-height: 1.35rem;
      /deep/ .el-link {
        font-size: 12px;
        color: #2878FF;
      }
    }
  }
}
</style>
